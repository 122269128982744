.bannerList {
  display: flex;
  flex-direction: column;
  height: 100%;
}
.bannerList .FormBox {
  flex: 1;
}
.btn-box {
  padding: 20px 20%;
  display: flex;
  justify-content: center;
}
.btn-box button {
  padding: 12px 30px;
}
.labelTop /deep/ .el-form-item__label {
  width: 7.5rem !important;
}
.labelTop /deep/ .el-form-item__content {
  margin-left: 0!important;
}
 /deep/ .el-table th.el-table__cell {
  padding: 0;
}
